<template>
    <div>
        <b-img id="slime" :src="requireSlimeTypeImgSrc(type)" @click="collectSlime"
               :style="{top: 'calc(' + top + 'vh - 128px)', left: 'calc(' + left + 'vw - 128px)'}"/>
    </div>
</template>

<script>
    import {requireSlimeTypeImgSrc} from '@/util/slime';
    import {apiPath}                from '@/util/http';

    const CollectSlimeModal = () => import('@/components/modals/slime/CollectSlimeModal');

    export default {
        name: "MovingSlime",
        props: {
            type: {
                type: String,
                required: true
            }
        },
        data: () => ({
            interval: null,
            top: 0,
            left: 0
        }),
        methods: {
            requireSlimeTypeImgSrc,
            moveSlime() {
                this.top  = Math.floor(Math.random() * 100);
                this.left = Math.floor(Math.random() * 100);
            },
            collectSlime() {
                this.$store.dispatch('slime/stop')
                    .then(() => this.axios.get(apiPath('collect_chased_slime')))
                    .then(response => this.$store.dispatch('modal/create', {
                        component: CollectSlimeModal,
                        props: {slime: response.data}
                    }))
                    .catch(() => this.$toaster.error('Impossible de capturer le slime'));
            }
        },
        mounted() {
            this.interval = setInterval(() => this.moveSlime(), 660);
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
    }
</script>

<style scoped>
    #slime {
        cursor: pointer;
        position: absolute;
        transition: all .66s;
        z-index: 10;
        max-width: calc(100vw / 3);
        max-height: calc(100vw / 3);
    }
</style>
